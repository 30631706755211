.react-datepicker {
  font-family: inherit !important;
  border: 1px solid #dddddd !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 500 !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  border-bottom: 2px solid #418eb3;
}

.react-datepicker__header,
.react-datepicker__today-button {
  background: #fff !important;
}

.react-datepicker__today-button:hover {
  background: #e4e5e7 !important;
}

.react-datepicker__today-button {
  font-weight: 400 !important;
  font-size: 12px !important;
}

.react-datepicker__navigation-icon {
  top: 5px !important;
}

.react-datepicker-popper {
  min-width: 520px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 15px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 1.8rem !important;
  line-height: 1.8rem !important;
  margin: 0.17rem !important;
}

/** Fixes the close icon not being clickable while being inside the textinput **/
.react-datepicker__close-icon {
  z-index: 1;
}

.react-datepicker__close-icon:after {
  font-size: 19px !important;
  background-color: unset !important;
  color: red !important;
}

@media only screen and (max-width: 768px) {
  .react-datepicker-popper {
    min-width: unset;
  }

  .react-datepicker__month-container {
    float: none !important;
  }
}

.react-datepicker__day--highlighted:not(.react-datepicker__day--selected) {
  background-color: #e0f7da !important;
  color: #404040;
}

.react-datepicker__day--highlighted:not(.react-datepicker__day--selected):hover {
  background-color: #c1e7b7 !important;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

/** upgrade 4.8.0 to 4.10.0 : new div added which breaks the 'next xxx days' display **/
.react-datepicker__children-container {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}
