/** Have the select options using their content width **/
div[id$='-listbox'],
div[id*='-listbox '] {
  width: max-content;
  min-width: 100%;
  color: #262626;
}

/** Resize the react select height **/
.ChakraReactSelect > div {
  height: 35px;
  min-height: 35px;
  cursor: pointer;
}

.ChakraReactSelectMulti > div:first-of-type {
  min-height: 35px;
  cursor: pointer;
}

/* less padding and more space to display the result and the "+N ..." */
.ChakraReactSelectMulti > div:first-of-type > div:first-of-type {
  padding-inline-start: 0.5rem;
  padding-inline-end: 0;
  flex-wrap: nowrap;
}

/** When there's no option in the select **/
div:has(> .SelectMessage) {
  padding: 0;
}

.ChakraReactSelect,
.ChakraReactSelectMulti {
  flex-grow: 1;
}

/** CSS for the footer lang selector (which is very specific!)  **/
#applang .applang__control {
  min-height: 25px;
  height: 25px;
  border-radius: 0.375rem;
  border: 1px solid #cccccc;
  /** no big border on focus **/
  box-shadow: none;
}

#applang .applang__value-container {
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.5rem;
}

#applang .applang__dropdown-indicator {
  margin: 0;
}
/** END of the CSS for the footer lang selector  **/
